











































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LandingPageAPI extends Vue {
  @Prop({ required: true }) apiType!: string;

  get apiFullName() {
    if (this.apiType === "sled") return "State & Local APIs";
    else if (this.apiType === "eis") return "Federal EIS APIs";
    else return "ServiceNow APIs";
  }
}
